// export const API_ROOT = "https://node.ccdemostore.com:8080/admin/"
// export const API_ROOT = "https://cc-demo-shopify.herokuapp.com/admin/";
// export const API_ROOT = "https://node.ccdemostore.com/admin";
export const API_ROOT = process.env.REACT_APP_API_ENDPOINT
// export const API_ROOT = "https://ccdemostaging.herokuapp.com/admin/";
export const CENTRAL_DASHBOARD_API = "https://central.ccdemostore.com/"

let category_api = "api/v1/categories"
let product_api = "api/v1/products/"

let order_api = "api/v1/myorders"

let my_inventory_product_api = "/api/v1/myinventory/"
let my_inventory_category_api = "/api/v1/categories/myinventory/"
let send_return_mail = "/api/v1/freereturn/sendemail"

let get_collection = "/api/v1/myinventory/getCollection"
let productaddtoshopify = "/api/v1/myinventory/addtoshopify"
let removeproductfromShopify = "/api/v1/myinventory/removeProductFromShopify"

let get_marketing_banner_api =
  CENTRAL_DASHBOARD_API + "api/banner/getall/?platform=isin_shopify"
let check_maintainance_mode_api = CENTRAL_DASHBOARD_API + "api/setting/"
let get_all_subscription =
  CENTRAL_DASHBOARD_API + "api/shopifypricing/allPrices"
let get_popup_info = CENTRAL_DASHBOARD_API + "api/popup/getfrontpopup"
// let get_marketing_banner_api = 'https://ccdemo-dashboard.herokuapp.com/api/banner/getall/?platform=isin_shopify';
// let check_maintainance_mode_api = 'https://ccdemo-dashboard.herokuapp.com/api/setting/';
// let get_all_subscription = 'https://ccdemo-dashboard.herokuapp.com/api/shopifypricing/allPrices';

// payment apis
let payment_data = "/api/v1/myaccount"
let cancel_subscription = "/api/v1/cancel_subscription"
let create_charge = "/api/v1/createChargeInShopify"

let home_page_banners = CENTRAL_DASHBOARD_API + "api/homebanner/getall"

// Order Sync
let payment_checkout = "/api/v1/checkout"
let validate_payment = "/api/v1/validate-payment"
// Order Sync

// Fulfillment Service
let manual_fulfillment_service = "/api/v1/manual/fulfillmentservices"
// Fulfillment Service

let add_to_cart = "/api/v1/cart"

// Search Suggestion API
let search_suggestion = "/api/v1/search-suggestions"
// Search Suggestion API

// all api end points
export const API_END_POINTS = {
  FETCH_CATEGORIES: category_api,
  FETCH_PRODUCTS: product_api,
  FETCH_MY_INVENTORY_PRODUCTS: my_inventory_product_api,
  FETCH_MY_INVENTORY_CATEGORY: my_inventory_category_api,
  GET_MARKETING_BANNERS: get_marketing_banner_api,
  CHECK_MAINTAINANCE_MODE: check_maintainance_mode_api,
  GET_ORDERS: order_api,
  SEND_RETURN_MAIL: send_return_mail,
  GET_SHOPIFY_COLLECTION: get_collection,
  PRODUCT_ADD_TO_SHOPIFY: productaddtoshopify,
  REMOVE_PRODUCT_FROM_SHOPIFY: removeproductfromShopify,
  GET_PAYMENT_INFO: payment_data,
  CANCEL_SUBSCRIPTION: cancel_subscription,
  CREATE_CHARGE: create_charge,
  SHOPIFY_SUBSCRIPTION: get_all_subscription,
  FETCH_POPUP_INFO: get_popup_info,
  FETCH_HOME_PAGE_BANNERS: home_page_banners,
  PAYMENT_CHECKOUT: payment_checkout,
  VALIDATE_PAYMENT: validate_payment,
  MANUAL_FULFILLMENT_SERVICE: manual_fulfillment_service,
  ADD_TO_CART: add_to_cart,
  SEARCH_SUGGESTION: search_suggestion
}
