import { API_END_POINTS } from "../../constant/config"
import JsonInterceptor from "../../utils/JsonInterceptor"
import {
  GET_ALL_INVENTORY_PRODUCTS,
  GET_MY_INVENTORY_PRODUCTS,
  GET_SHOPIFY_COLLECTION,
  RESET_LOADING,
  PRODUCT_ADD_TO_SHOPIFY,
  REMOVE_PRODUCT_FROM_SHOPIFY,
  ADD_TO_CART,
  CART_ITEMS_COUNT
} from "../types"
import axios from "axios"

// Fetch All Products
export const fetchAllProducts = (query, shop) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.FETCH_PRODUCTS}?page=${query.pageNumber}&limit=${query.pageSize}&search=${query.searchValue}&categories=${query.categories}&short_by=${query.short_by}&store=${shop}`
    const response = await JsonInterceptor.get(`${apiEndpoint}`)

    dispatch({
      type: GET_ALL_INVENTORY_PRODUCTS,
      payload: response.data.data
    })

    const response1 = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/cart?shop=${shop}&count=true`
    )

    dispatch({
      type: CART_ITEMS_COUNT,
      payload: { count: response1?.data?.data?.totalQuantity || 0 }
    })
  } catch (error) {
    console.log("error", error)
  }
}

// Fetch My Inventory Products
export const fetchMyInventoryProducts = (query, shop) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.FETCH_MY_INVENTORY_PRODUCTS}?page=${query.pageNumber}&limit=${query.pageSize}&search=${query.searchValue}&categories=${query.categories}&short_by=${query.short_by}&store=${shop}`
    const response = await JsonInterceptor.get(`${apiEndpoint}`)

    dispatch({
      type: GET_MY_INVENTORY_PRODUCTS,
      payload: response.data.data
    })
  } catch (error) {
    console.log("error", error)
  }
}

// Fetch My Inventory Products
export const getShopifyCollection = (query, shop) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.GET_SHOPIFY_COLLECTION}?shop=${shop}`
    const response = await JsonInterceptor.get(`${apiEndpoint}`)

    dispatch({
      type: GET_SHOPIFY_COLLECTION,
      payload: response.data.data
    })
  } catch (error) {
    console.log("error", error)
  }
}

export const productaddtoShopify = (data) => async (dispatch) => {
  try {
    const apiEndpoint = API_END_POINTS.PRODUCT_ADD_TO_SHOPIFY
    const response = await JsonInterceptor.post(`${apiEndpoint}`, data)
    return dispatch({
      type: PRODUCT_ADD_TO_SHOPIFY,
      payload: response.data
    })
  } catch (error) {
    if (error.response) {
      return dispatch({
        type: PRODUCT_ADD_TO_SHOPIFY,
        payload: error.response.data
      })
    }
  }
}

export const removeproductfromShopify = (data) => async (dispatch) => {
  try {
    const apiEndpoint = API_END_POINTS.REMOVE_PRODUCT_FROM_SHOPIFY
    const response = await JsonInterceptor.delete(`${apiEndpoint}`, {
      data: data
    })
    return dispatch({
      type: REMOVE_PRODUCT_FROM_SHOPIFY,
      payload: response.data
    })
  } catch (error) {
    if (error.response) {
      return dispatch({
        type: REMOVE_PRODUCT_FROM_SHOPIFY,
        payload: error.response.data
      })
    }
  }
}

export const resetLoading = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_LOADING
    })
  } catch (error) {
    console.log("error", error)
  }
}

export const productaddtoCart = (data, shop) => async (dispatch) => {
  try {
    let reqdata = {
      productId: data._id,
      quantity: 1,
      shop: shop
    }
    if (data?.size) {
      reqdata.size = data.size
    }
    const apiEndpoint = API_END_POINTS.ADD_TO_CART
    const response = await JsonInterceptor.post(`${apiEndpoint}`, reqdata)
    const response1 = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/cart?shop=${shop}&count=true`
    )

    dispatch({
      type: CART_ITEMS_COUNT,
      payload: { count: response1?.data?.data?.totalQuantity || 0 }
    })

    return dispatch({
      type: ADD_TO_CART,
      payload: response.data
    })
  } catch (error) {
    if (error.response) {
      return dispatch({
        type: ADD_TO_CART,
        payload: error.response.data
      })
    }
  }
}

export const fetchsearchSuggestion = (data) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.SEARCH_SUGGESTION}`
    const response = await JsonInterceptor.post(`${apiEndpoint}`, data)
    return dispatch({
      type: "SEARCH_SUGGESTION",
      payload: response.data
    })
  } catch (error) {
    console.log("error", error)
  }
}
