/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Carousel } from "antd"
import "antd/dist/antd.css"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../_helpers"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Popover } from "antd"
import {
  categoryFilter,
  searchKeyword,
  sortFilter
} from "../../../../../actions/common"
import { Tree } from "antd"
import { useHistory } from "react-router-dom"

export default function AsideToggelMenu({ layoutProps }) {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [catDisplayName, setcatDisplayName] = useState(false)
  const [searchValue, setsearchValue] = useState("")
  const [clearSearchflag, setclearSearchflag] = useState(false)
  const [selctedsortValue, setselctedsortValue] = useState("new_to_old")
  const dispatch = useDispatch()
  const history = useHistory()

  const headerMenu = [
    "/my-orders",
    "/marketing-material",
    "/my-account",
    "/help",
    "/return",
    "/cart",
    "/trunkey",
    "/upgrade-save"
  ]

  function addQueryParamsToURL(newParams) {
    // Get the current URL
    const currentURL = window.location.href

    // Parse the URL to extract the existing query parameters
    const url = new URL(currentURL)

    // Add or update the new query parameters
    for (const param in newParams) {
      url.searchParams.set(param, newParams[param])
    }

    const pathAndQuery = "/all-inventory" + url.search

    // Build the updated URL with the combined parameters
    const updatedURL = pathAndQuery.toString()

    // Use history.pushState to update the browser URL
    history.push(updatedURL)
  }

  const { categories, searchKey, sortValue } = useSelector(
    (state) => ({
      categories: state.common && state.common?.categoryID,
      searchKey: state.common && state.common?.searchKeyword,
      sortValue: state.common && state.common?.sortFilter
    }),
    shallowEqual
  )

  useEffect(() => {
    if (categories === null || searchKey === null || sortValue === null) {
      if (categories === null) {
        setcatDisplayName(false)
        setSelectedCategory(null)
      }

      if (searchKey === null) {
        setclearSearchflag(false)
        setsearchValue("")
      }

      if (sortValue === "new_to_old") {
        setselctedsortValue("new_to_old")
      }
    }
  }, [categories, searchKey, sortValue])

  useEffect(() => {
    if (categories !== null) {
      dispatch(searchKeyword({ searchKeyword: null }))
      setclearSearchflag(false)
      setsearchValue("")
    } else {
      setcatDisplayName(false)
      setSelectedCategory(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  useEffect(() => {
    if (searchKey !== null) {
      dispatch(categoryFilter({ categoryId: null }))
      setcatDisplayName(false)
      setSelectedCategory(null)
    } else {
      setclearSearchflag(false)
      setsearchValue("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey])

  const selected_category = (event, node) => {
    if (categories == node.node.key && catDisplayName) {
      dispatch(categoryFilter({ categoryId: null }))
      setcatDisplayName(false)
      setSelectedCategory(null)
    } else {
      dispatch(categoryFilter({ categoryId: node.node.key }))
      setcatDisplayName(true)
      setSelectedCategory(node.node.title)
    }

    if (window.location.pathname !== "/all-inventory") {
      const newQueryParams = {
        filter: true
      }

      addQueryParamsToURL(newQueryParams)
    }

    // if (catDisplayName) {
    //   dispatch(categoryFilter({ categoryId: null }))
    //   setcatDisplayName(false)
    //   setSelectedCategory(null)
    // } else {
    //   dispatch(categoryFilter({ categoryId: node.node.key }))
    //   setcatDisplayName(true)
    //   setSelectedCategory(node.node.title)
    // }
  }

  // check shop exist or not
  const path = window.location.href.replace(/^.+\?/i, "")
  const searchParams = new URLSearchParams(path)
  const shop = searchParams.get("shop")
  const host = searchParams.get("host")

  const searchVal = () => {
    if (searchValue !== "") {
      if (headerMenu.includes(history?.location?.pathname)) {
        const redirectURL = `/all-inventory?shop=${shop}&host=${host}`
        history.push(redirectURL)
        setTimeout(() => {
          dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
          setclearSearchflag(true)
        }, 3000)
      } else {
        setTimeout(() => {
          dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
          setclearSearchflag(true)
        }, 2000)
      }
      // dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
      // setclearSearchflag(true)
    }
  }

  const clearSearch = () => {
    dispatch(searchKeyword({ searchKeyword: null }))
    setclearSearchflag(false)
    setsearchValue("")
  }

  const handsortingChange = (e) => {
    dispatch(sortFilter({ sortFilter: e.target.value }))
    setselctedsortValue(e.target.value)
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      searchVal()
    }
  }

  useEffect(() => {
    if (
      headerMenu.includes(history?.location?.pathname) &&
      (categories !== null || searchKey !== null || sortValue !== null)
    ) {
      if (categories) {
        dispatch(categoryFilter({ categoryId: null }))
        setcatDisplayName(false)
        setSelectedCategory(null)
        setSelectedKeys([])
      }

      if (searchKey) {
        dispatch(searchKeyword({ searchKeyword: null }))
        setclearSearchflag(false)
        setsearchValue("")
      }
      // console.log("Need to remove.......")
      // var redirectionURL = getRedirectionURL(categories,searchKey,sortValue)
      // var redirectionURL = '/all-inventory?shop=myonlineteststoreforapp.myshopify.com&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbXlvbmxpbmV0ZXN0c3RvcmVmb3JhcHA&bypass=22868300104343534024&redirect=true'
      // history.push(redirectionURL)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, history.location.pathname, searchKey, sortValue])

  const [selectedKeys, setSelectedKeys] = useState([])

  const content = (
    <Tree
      treeData={layoutProps.categoryTree}
      onSelect={selected_category}
      selectedKeys={selectedKeys}
    />
  )

  const cat_title = catDisplayName
    ? `Categories (${selectedCategory.replace(/ *\([^)]*\) */g, "")})`
    : "Categories"

  const searchContent = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon2"
            onChange={(e) => setsearchValue(e.target.value)}
            value={searchValue}
            onKeyDown={handleKeypress}
          />
          <div className="input-group-append">
            <span
              className="input-group-text"
              onClick={searchVal}
              style={{ cursor: "pointer" }}
            >
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        {clearSearchflag && (
          <a
            href={void 0}
            className="btn submit"
            style={{ float: "right", fontSize: "12px" }}
            id="clear_search"
            onClick={clearSearch}
          >
            Clear &nbsp;
            <i className="quick-search-close ki ki-close icon-sm text-muted"></i>
          </a>
        )}
      </div>
    </div>
  )

  const filterbyContent = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <select
          id="filter_by"
          name="filter_by"
          className="form-control selectpicker"
          value={selctedsortValue}
          onChange={(e) => {
            handsortingChange(e)
          }}
        >
          <option value="new_to_old">Newest to Oldest</option>
          <option value="old_to_new">Oldest to Newest</option>
          <option value="low_to_high">Cost: Low to High</option>
          <option value="high_to_low">Cost: High to Low</option>
          <option value="qty_low_to_high">Qty: Low to High</option>
          <option value="qty_high_to_low">Qty: High to Low</option>
        </select>
      </div>
    </div>
  )

  const Image1 = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Link to={{ pathname: "upgrade-save" }}>
          <img
            src={toAbsoluteUrl("assets/images/upgrade_save.jpg")}
            alt="Upgrade & Save"
            className="img-fluid pr-5 pl-5"
          />
        </Link>
      </div>
    </div>
  )

  const Image2 = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Link
          to={{
            pathname:
              "https://www.myonlinefashionstore.com/products/pre-programmed-pre-designed-pre-loaded-with-our-items-ready-to-launch-website"
          }}
          target="_blank|_self|_parent|_top|app-iframe"
        >
          <img
            src={toAbsoluteUrl("assets/images/99PACKAGE.jpg")}
            alt="99 Package"
            className="img-fluid pr-5 pl-5"
          />
        </Link>
      </div>
    </div>
  )

  const Image3 = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Link
          to={{
            pathname:
              "https://www.myonlinefashionstore.com/pages/custom-packaging"
          }}
          target="_blank|_self|_parent|_top|app-iframe"
        >
          <img
            src={toAbsoluteUrl("assets/images/SQUARELAYOUT.jpg")}
            alt="SQUARELAYOUT"
            className="img-fluid pr-5 pl-5"
          />
        </Link>
      </div>
    </div>
  )

  const Slider = (
    <div className="form-group row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Carousel>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/SOLDmar2021.jpg")}
              alt="SOLDmar2021.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS2.jpg")}
              alt="HELPFULTIPS2.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS3.jpg")}
              alt="HELPFULTIPS3.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS4.jpg")}
              alt="HELPFULTIPS4.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS5.jpg")}
              alt="HELPFULTIPS5.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS6.jpg")}
              alt="HELPFULTIPS6.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS7.jpg")}
              alt="HELPFULTIPS7.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS8.jpg")}
              alt="HELPFULTIPS8.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={toAbsoluteUrl("assets/images/HELPFULTIPS9.jpg")}
              alt="HELPFULTIPS9.jpg"
              className="img-fluid pr-5 pl-5"
            />{" "}
          </div>
        </Carousel>
      </div>
    </div>
  )

  return (
    <>
      <div className="toggle-section-div">
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <Popover
            content={content}
            title={cat_title}
            trigger="hover"
            placement="rightTop"
          >
            <li
              className="menu-item"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-layer-group"></i>
                </span>
              </a>
            </li>
          </Popover>

          {/* <Popover
            content={searchContent}
            title="Search"
            trigger="hover"
            placement="right"
          >
            <li
              className="menu-item"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-search"></i>
                </span>
              </a>
            </li>
          </Popover> */}

          <Popover
            content={filterbyContent}
            title="Filter By"
            trigger="hover"
            placement="right"
          >
            <li
              className="menu-item mb-5"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-filter"></i>
                </span>
              </a>
            </li>
          </Popover>

          {/* <Popover content={Image1} title="" trigger="hover" placement="right">
            <div className="form-group row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Link to={{ pathname: 'upgrade-save' }}><img src={toAbsoluteUrl('assets/images/upgrade_save.jpg')} alt="Upgrade & Save" className="img-fluid pr-5 pl-5" /></Link>
              </div>
            </div>
          </Popover>

          <Popover content={Image2} title="" trigger="hover" placement="right">
            <div className="form-group row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Link to={{ pathname: "https://www.myonlinefashionstore.com/products/pre-programmed-pre-designed-pre-loaded-with-our-items-ready-to-launch-website" }} target="_blank|_self|_parent|_top|app-iframe"><img src={toAbsoluteUrl('assets/images/99PACKAGE.jpg')} alt="99 Package" className="img-fluid pr-5 pl-5" /></Link>
              </div>
            </div>
          </Popover>

          <Popover content={Image3} title="" trigger="hover" placement="right">
            <div className="form-group row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Link to={{ pathname: "https://www.myonlinefashionstore.com/pages/custom-packaging" }} target="_blank|_self|_parent|_top|app-iframe"><img src={toAbsoluteUrl('assets/images/SQUARELAYOUT.jpg')} alt="SQUARELAYOUT" className="img-fluid pr-5 pl-5" /></Link>
              </div>
            </div>
          </Popover>

          <Popover content={Slider} title="" trigger="hover" placement="right">
            <div className="form-group row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <img src={toAbsoluteUrl('assets/images/SOLDmar2021.jpg')} alt="SOLDmar2021.jpg" className="img-fluid pr-5 pl-5" />
              </div>
            </div>
          </Popover> */}
        </ul>
      </div>
      {/* <div className="form-group row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Carousel>
            <div> <img src={toAbsoluteUrl('assets/images/SOLDmar2021.jpg')} alt="SOLDmar2021.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS2.jpg')} alt="HELPFULTIPS2.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS3.jpg')} alt="HELPFULTIPS3.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS4.jpg')} alt="HELPFULTIPS4.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS5.jpg')} alt="HELPFULTIPS5.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS6.jpg')} alt="HELPFULTIPS6.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS7.jpg')} alt="HELPFULTIPS7.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS8.jpg')} alt="HELPFULTIPS8.jpg" className="img-fluid pr-5 pl-5" /> </div>
            <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS9.jpg')} alt="HELPFULTIPS9.jpg" className="img-fluid pr-5 pl-5" /> </div>
          </Carousel>
        </div>
      </div> */}
    </>
  )
}
