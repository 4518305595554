/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {
  useMemo,
  useLayoutEffect,
  useEffect,
  useState,
  useRef
} from "react"
import objectPath from "object-path"
import { useLocation } from "react-router-dom"
import { QuickActions } from "./components/QuickActions"
import { BreadCrumbs } from "./components/BreadCrumbs"
import {
  getBreadcrumbsAndTitle,
  useSubheader
} from "../../_core/MetronicSubheader"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_helpers"
import { SearchResult } from "../extras/dropdowns/search/SearchResult"
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown"
import clsx from "clsx"
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns"
import { fetchsearchSuggestion } from "../../../../actions/products/products"
import { useHistory } from "react-router-dom"
import { categoryFilter, searchKeyword } from "../../../../actions/common"
import { debounce } from "lodash"

export function SubHeader() {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const subheader = useSubheader()

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    }
  }, [uiService])

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname)
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname)
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    )
    // eslint-disable-next-line
  }, [location.pathname])

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader])

  // get myinventory count
  const { count } = useSelector(
    (state) => ({
      count:
        state.products &&
        state.products.myinventoryproduct &&
        state.products.myinventoryproduct.totalDocs
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const fakeData = [
    {
      type: 0,
      text: "Reports"
    },
    {
      type: 1,
      text: "AirPlus Requirements",
      description: "by Grog John",
      iconImage: toAbsoluteUrl("/media/files/doc.svg")
    },
    {
      type: 1,
      text: "TechNav Documentation",
      description: "by Mary Brown",
      iconImage: toAbsoluteUrl("/media/files/pdf.svg")
    },
    {
      type: 1,
      text: "All Framework Docs",
      description: "by Grog John",
      iconImage: toAbsoluteUrl("/media/files/zip.svg")
    },
    {
      type: 1,
      text: "AirPlus Requirements",
      description: "by Tim Hardy",
      iconImage: toAbsoluteUrl("/media/files/xml.svg")
    },
    {
      text: "Customers",
      type: 0
    },
    {
      type: 1,
      text: "Jimmy Curry",
      description: "Software Developer",
      iconImage: toAbsoluteUrl("/media/users/300_11.jpg")
    },
    {
      type: 1,
      text: "Milena Gibson",
      description: "UI Designer",
      iconImage: toAbsoluteUrl("/media/users/300_16.jpg")
    },
    {
      type: 1,
      text: "Stefan JohnStefan",
      description: "Marketing Manager",
      iconImage: toAbsoluteUrl("/media/users/300_22.jpg")
    },
    {
      type: 1,
      text: "Anna Strong",
      description: "Software Developer",
      iconImage: toAbsoluteUrl("/media/users/300_5.jpg")
    },
    {
      type: 0,
      text: "Files"
    },
    {
      type: 1,
      text: "2 New items submitted",
      description: "Marketing Manager",
      iconClassName: "flaticon2-box font-danger"
    },
    {
      type: 1,
      text: "79 PSD files generated",
      description: "by Grog John",
      iconClassName: "flaticon-psd font-brand"
    },
    {
      type: 1,
      text: "$2900 worth products sold",
      description: "Total 234 items",
      iconClassName: "flaticon2-supermarket font-warning"
    },
    {
      type: 1,
      text: "4 New items submitted",
      description: "Marketing Manager",
      iconClassName: "flaticon-safe-shield-protection font-info"
    }
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const suggestionBoxOpen = Boolean(anchorEl)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const dropdownRef = useRef(null) // Ref for dropdown toggle
  const [showDropdown, setShowDropdown] = useState(false)
  const [recentSearches, setRecentSearches] = useState([])
  const [suggestions, setsuggestions] = useState([])

  const [clearSearchflag, setclearSearchflag] = useState(false)
  let timeoutId

  const timeoutRef = useRef(null)

  const history = useHistory()

  const headerMenu = [
    "/my-orders",
    "/marketing-material",
    "/my-account",
    "/help",
    "/return",
    "/cart",
    "/trunkey",
    "/upgrade-save"
  ]

  const { categories, searchKey, sortValue } = useSelector(
    (state) => ({
      categories: state.common && state.common?.categoryID,
      searchKey: state.common && state.common?.searchKeyword,
      sortValue: state.common && state.common?.sortFilter
    }),
    shallowEqual
  )

  // Load previous search history from localStorage
  useEffect(() => {
    const storedSearches =
      JSON.parse(localStorage.getItem("recentSearches")) || []
    setRecentSearches(storedSearches)
  }, [])

  // Save recent searches to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches))
  }, [recentSearches])

  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim()
    if (!trimmedSearchTerm) return // Prevent empty searches

    // Add the new search term at the beginning and remove duplicates
    const updatedSearches = [
      trimmedSearchTerm,
      ...recentSearches.filter((s) => s !== trimmedSearchTerm)
    ].slice(0, 5) // Keep only the last 5 searches

    setRecentSearches(updatedSearches)
    // setSearchValue("") // Clear input field after search
  }

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  const handleSearchDebounced = debounce(async (event) => {
    event.persist()
    console.log(event.target.value, "newValue....")
    const searchBody = {
      query: event.target.value
    }
    // setTimeout(async () => {
    setLoading(true)
    let suggestionsArray = await dispatch(fetchsearchSuggestion(searchBody))
    suggestionsArray = suggestionsArray.payload.data.suggestions || []
    setsuggestions(suggestionsArray)
    setLoading(false)
    // }, 500)
  }, 500) // Adjust the delay time as needed

  const handleSearchChange = (event) => {
    event.persist()
    setData(null)
    setsuggestions([])
    setSearchValue(event.target.value)
    handleSearchDebounced(event)
  }

  const clear = () => {
    setData(null)
    setSearchValue("")
    dispatch(searchKeyword({ searchKeyword: null }))
    // setShowDropdown(false)
    setAnchorEl(null)
    setsuggestions([])
  }

  const handleInputFocus = (e) => {
    e.persist() // Prevent React from clearing the event

    setTimeout(() => {
      // setShowDropdown(true)
      setAnchorEl(e.target)
    }, 500)

    if (searchValue.length === 0) {
      setData(recentSearches)
    }
  }

  // Remove a search term from localStorage
  const handleRemoveSearch = (termToRemove) => {
    setTimeout(() => {
      const updatedSearches = recentSearches.filter(
        (term) => term !== termToRemove
      )
      setRecentSearches(updatedSearches)
      setData(updatedSearches)
    }, 1000)
  }

  // check shop exist or not
  const path = window.location.href.replace(/^.+\?/i, "")
  const searchParams = new URLSearchParams(path)
  const shop = searchParams.get("shop")
  const host = searchParams.get("host")

  // const searchVal = () => {
  //   if (searchValue !== "") {
  //     if (headerMenu.includes(history?.location?.pathname)) {
  //       const redirectURL = `/all-inventory?shop=${shop}&host=${host}`
  //       history.push(redirectURL)
  //       setTimeout(() => {
  //         dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
  //         setclearSearchflag(true)
  //       }, 3000)
  //     } else {
  //       setTimeout(() => {
  //         dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
  //         setclearSearchflag(true)
  //       }, 2000)
  //     }

  //     // dispatch(searchKeyword({ searchKeyword: searchValue.trim() }))
  //     // setclearSearchflag(true)
  //     // getRedirectionURL(categories,searchValue.trim(),sortValue)
  //   }
  // }

  useEffect(() => {
    if (
      headerMenu.includes(history?.location?.pathname) &&
      (categories !== null || searchKey !== null || sortValue !== null)
    ) {
      if (categories) {
        dispatch(categoryFilter({ categoryId: null }))
      }

      if (searchKey) {
        dispatch(searchKeyword({ searchKeyword: null }))
        setclearSearchflag(false)
        setSearchValue("")
      }
      // console.log("Need to remove.......")
      // var redirectionURL = getRedirectionURL(categories,searchKey,sortValue)
      // var redirectionURL = '/all-inventory?shop=myonlineteststoreforapp.myshopify.com&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbXlvbmxpbmV0ZXN0c3RvcmVmb3JhcHA&bypass=22868300104343534024&redirect=true'
      // history.push(redirectionURL)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, history.location.pathname, searchKey])

  useEffect(() => {
    if (categories !== null) {
      dispatch(searchKeyword({ searchKeyword: null }))
      setclearSearchflag(false)
      setSearchValue("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const handleSearchfromRecent = (searchTerm) => {
    if (searchTerm !== "") {
      if (headerMenu.includes(history?.location?.pathname)) {
        const redirectURL = `/all-inventory?shop=${shop}&host=${host}`
        history.push(redirectURL)
        // setTimeout(() => {
        dispatch(searchKeyword({ searchKeyword: searchTerm.trim() }))
        setclearSearchflag(true)
        // }, 1000)
      } else {
        // setTimeout(() => {
        dispatch(searchKeyword({ searchKeyword: searchTerm.trim() }))
        setclearSearchflag(true)
        // }, 1000)
      }
    }
    setSearchValue(searchTerm)
    setAnchorEl(null)
    handleSearch(searchTerm)
    setsuggestions([])
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSearchfromRecent(searchValue)
      handleSearch(searchValue)
      setsuggestions([])
    }
  }

  useEffect(() => {
    if (searchValue.length <= 0) {
      setData(recentSearches)
      setAnchorEl("something")
      dispatch(searchKeyword({ searchKeyword: null }))
    }
  }, [searchValue])

  // justify-content-between
  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center flex-wrap flex-sm-nowrap justify-content-between advanced_search_wrapper`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1 left-cnt">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>{subheader.title}</>
              {/*<small></small>*/}
            </h5>
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
          <div className="d-flex align-items-baseline mr-5 ml-6">
            <h5 className="text-dark font-weight-bold my-2 mr-5 mb-4 mt-4">
              <div
                id="kt_quick_search_dropdown"
                className="quick-search quick-search-dropdown position-relative"
                style={{ width: "400px" }}
              >
                <div className="quick-search-form">
                  <div className="input-group text-black">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={handleSearchChange}
                      className="form-control adv_search"
                      onKeyDown={handleKeypress}
                      onFocus={handleInputFocus}
                      onClick={handleInputFocus}
                    />
                    <div
                      className={`input-group-append ${
                        loading ? "spinner spinner-sm spinner-primary" : ""
                      }`}
                    >
                      <span className="input-group-text">
                        <i
                          style={{
                            display: loading ? "none" : "flex",
                            // color: "#000", // Ensures proper black
                            // opacity: 1 // Ensures it's not faded
                          }}
                          // onClick={() => (searchValue ? clear() : undefined)}
                          onClick={() => clear()}
                          className={`quick-search-close ${
                            searchValue ? `fa fa-times` : `fa fa-search`
                          } icon-md text-black`}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="adv_search_dropdown w-100">
                  <Dropdown show={suggestionBoxOpen} onToggle={setAnchorEl}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      ref={dropdownRef}
                      className="d-none"
                    />
                    <Dropdown.Menu
                      className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg dropdown-menu-xl"
                      style={{
                        width: "100%",
                        left: "0 !important",
                        right: "auto !important",
                        transform: "none !important",
                        position: "absolute",
                        top: "100%",
                        marginLeft: "0 !important",
                        paddingLeft: "0 !important"
                      }}
                    >
                      <div
                        className={clsx("quick-search quick-search-popup", {
                          "quick-search-has-result":
                            (suggestions && suggestions.length) ||
                            (data && data.length)
                        })}
                      >
                        <SearchResult
                          data={data}
                          suggestions={suggestions}
                          handleRemoveSearch={handleRemoveSearch}
                          handleSearchfromRecent={handleSearchfromRecent}
                        />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </h5>
          </div>
        </div>

        {/* </div> */}

        {/* Toolbar */}
        {location && location.pathname == "/my-inventory" && count >= 0 ? (
          <div className="d-flex align-items-center right-cnt">
            {/* <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
              data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Total amount of items added</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">50</span>
            </a> */}
            <button
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
              className="btn btn-info btn-sm font-weight-bold"
            >
              <span
                className="font-weight-bold"
                id="kt_dashboard_daterangepicker_title"
              >
                Total added items :{" "}
              </span>
              <span
                className="font-weight-bolder"
                id="kt_dashboard_daterangepicker_date"
              >
                {count !== null ? count : 0}
              </span>
            </button>
            {/* <QuickActions/> */}
          </div>
        ) : (
          <div className="d-flex align-items-center right-cnt"></div>
        )}
      </div>
    </div>
  )
}
