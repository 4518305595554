/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import SVG from "react-inlinesvg"
import PerfectScrollbar from "react-perfect-scrollbar"
import { toAbsoluteUrl } from "../../../../../_helpers"

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
}

export function SearchResult({
  data,
  suggestions,
  handleRemoveSearch,
  handleSearchfromRecent
}) {
  if (data && data.length == 0 && suggestions && suggestions == 0) {
    return null
  }

  // if (data.length === 0) {
  //   return (
  //     <div
  //       style={{ maxHeight: "325px", overflow: "hidden" }}
  //       className="quick-search-wrapper scroll ps ps--active-y"
  //     >
  //       <div className="quick-search-result">
  //         {/* <div className="text-muted d-none">No record found</div> */}

  //         <div className="font-size-lg text-theme-color font-weight-bolder text-uppercase mb-3">
  //           No recent searches available
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div>
      {suggestions || data ? (
        <div
          style={{ maxHeight: "325px", overflow: "hidden" }}
          className="quick-search-wrapper scroll ps ps--active-y"
        >
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="scroll"
            style={{ maxHeight: "325px", position: "relative" }}
          >
            <div className="quick-search-result pl-4">
              {suggestions && suggestions.length > 0 ? (
                <>
                  <div className="mb-5">
                    {suggestions?.map((sugg, index) => (
                      <div
                        className="d-flex align-items-center flex-grow-1 mb-2 justify-content-between"
                        style={{ paddingRight: "20px" }}
                        key={index}
                      >
                        <div className="d-flex align-items-center flex-grow-1 mb-2">
                          <div
                            className="flex-shrink-0"
                            style={{ marginTop: "5px" }}
                          >
                            <div className="symbol-label">
                              <i className="flaticon-search text-theme-color"></i>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-column ml-3 mb-2"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href="#"
                              className="font-weight-bold text-dark text-hover-theme"
                              onClick={(e) => {
                                e.preventDefault()
                                handleSearchfromRecent(sugg)
                              }}
                            >
                              {sugg}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {data && data?.length > 0 ? (
                    <>
                      <div className="font-size-lg text-theme-color font-weight-bolder mb-3">
                        Recent Searches
                      </div>
                      <div className="mb-2">
                        {data?.map((recent_search, index) => (
                          <div
                            className="d-flex align-items-center flex-grow-1 mb-2 justify-content-between"
                            style={{ paddingRight: "20px" }}
                            key={index}
                          >
                            <div className="d-flex align-items-center flex-grow-1 mb-2">
                              <div
                                className="flex-shrink-0"
                                style={{ marginTop: "5px" }}
                              >
                                <div className="symbol-label">
                                  <i className="flaticon2-time text-theme-color"></i>
                                </div>
                              </div>
                              <div
                                className="d-flex flex-column ml-3 mb-2"
                                style={{ marginTop: "10px" }}
                              >
                                <a
                                  href="#"
                                  className="font-weight-bold text-dark text-hover-theme"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleSearchfromRecent(recent_search)
                                  }}
                                >
                                  {recent_search}
                                </a>
                              </div>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="symbol-label">
                                <i
                                  className="flaticon2-cross icon-sm"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveSearch(recent_search)
                                  }
                                ></i>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      ) : null}
    </div>
  )
}
